import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// import VueI18n from 'vue-i18n'
import i18n from './i18n/index'
import './untils/rem'
import './untils/fonts/font.css'
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
Vue.use(ElementUI)
// Vue.use(VueI18n)
// var i18n = new VueI18n({
//   locale: 'zh' || localStorage.getItem('languageSet'), //从localStorage里获取用户中英文选择，没有则默认中文
//   messages: {
//     'zh': require('./i18n/lang/zh'),
//     'en': require('./i18n/lang/en'),
//   },
//   silentTranslationWarn: true
// })
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')