import Vue from 'vue'
import VueRouter from 'vue-router'
const Index = () => import('../views/pages/index.vue')
const Culture = () => import('../views/pages/culture.vue')
const Dynamic = () => import('../views/pages/trends/dynamic.vue')

const CaseDetails = () => import('../views/pages/CaseDetails/CaseDetails.vue')
const Certification = () => import('../views/pages/certification/certification.vue')//荣誉资质
const CustomerCase = () => import('../views/pages/CustomerCase/CustomerCase.vue')

const ExhibitionDetails = () => import('../views/pages/ExhibitionDetails/ExhibitionDetails.vue') //集团动态 展会详情
const LatestActivity = () => import('../views/pages/LatestActivity/LatestActivity.vue')
const activity = () => import('../views/pages/LatestActivity/activity.vue')

const Partner = () => import('../views/pages/partners/partner.vue')
const progress = () => import('../views/pages/progress/progress.vue')
const Position = () => import('../views/pages/Position/Position.vue')

const PositionDetails = () => import('../views/pages/PositionDetails/PositionDetails.vue')
const Relation = () => import('../views/pages/relation/relation.vue')
const Scheme = () => import('../views/pages/scheme/scheme.vue')

const Welfare = () => import('../views/pages/welfare/welfare.vue')
const presentation = () => import('../views/pages/presentation/presentation.vue')

// 产品
const cloudservices = () => import('../views/product/cloudservices.vue')
const faceRecognition = () => import('../views/product/faceRecognition.vue')
const pictureAnalysis = () => import('../views/product/pictureAnalysis.vue')
const characterRecognition = () => import('../views/product/characterRecognition.vue')
const LiveStream = () => import('../views/product/liveStream.vue')
const AutomaticSpeechRecognition = () => import('../views/product/AutomaticSpeechRecognition.vue')
const naturalLanguage = () => import('../views/product/naturalLanguage.vue')
const smart = () => import('../views/product/smart.vue')
const blockchain = () => import('../views/product/blockchain.vue')
const bigData = () => import('../views/product/bigData.vue')
const AgencyAccount = () => import('../views/product/AgencyAccount.vue')
const Commerce = () => import('../views/product/Commerce.vue')
const knowledge = () => import('../views/product/knowledge.vue')
const video = () => import('../views/product/video.vue')
const BuildStation = () => import('../views/product/BuildStation.vue')
const trademark = () => import('../views/product/trademark.vue')
const customizedDevelopment = () => import('../views/product/customizedDevelopment.vue')
const EcologicalCloud = () => import('../views/product/EcologicalCloud.vue')
const newRetail = () => import('../views/product/newRetail.vue')
const cloudServer = () => import('../views/product/cloudServer.vue')
const bonnitui = () => import('../views/product/bonnitui.vue')
const thirdpartnar = () => import('../views/product/thirdpartnar.vue')
const newMediaOperation = () => import('../views/product/newMediaOperation.vue')
const derivative = () => import('../views/product/derivative.vue')
const catering = () => import('../views/product/catering.vue')
const beauty = () => import('../views/product/beauty.vue')
const Wenbrigade = () => import('../views/product/Wenbrigade.vue')
const education = () => import('../views/product/education.vue')
const livestreaming = () => import('../views/product/livestreaming.vue')
const store = () => import('../views/product/store.vue')
const webpage = () => import('../views/product/webpage.vue')
const miniprogram = () => import('../views/product/miniprogram.vue')
const assistant = () => import('../views/product/assistant.vue')
const note = () => import('../views/product/note.vue')
const SoftTweets = () => import('../views/product/SoftTweets.vue')
const leaflet = () => import('../views/product/leaflet.vue')
const marketing = () => import('../views/product/marketing.vue')
const market = () => import('../views/product/market.vue')
const design = () => import('../views/product/design.vue')
const StoreSystem = () => import('../views/product/StoreSystem.vue')
const EducationSystem = () => import('../views/product/EducationSystem.vue')


const Tabbar = () => import('../components/tabbar')

// 错误页面
const Notfount = () => import('../views/notfount/notfount.vue')

Vue.use(VueRouter)
Vue.config.productionTip = false
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Tabbar',
    component: Tabbar,
    children: [{
      path: '/',
      name: 'Index',
      component: Index,
      meta: {
        title: '销售系统-共享店铺-软件定制-邦伲德集团官网',
        content: {
          keywords: '门店系统、销售系统、共享店铺',
          description: '邦伲德集团官网致力于为客户搭建智慧商业服务生态，公司主营门店系统、销售系统、共享店铺、共享商圈、软件定制等。目前拥有自主核心产品：企业建站、 SaaS产品、营销推广、代运营、企业服务、增值服务'
        }
      }
    }, {
      path: '/culture',
      name: 'Culture',
      component: Culture,
      meta: {
        title: '企业文化-邦伲德集团官网'
      }
    }, {
      path: '/liveStream',
      name: 'LiveStream',
      component: LiveStream,
      meta: {
        title: '5G直播-邦伲德集团官网'
      }
    }, {
      path: '/dynamic',
      name: 'Dynamic',
      component: Dynamic,
      meta: {
        title: '集团动态-邦伲德集团官网'
      }
    }, {
      path: '/CaseDetails',
      name: 'CaseDetails',
      component: CaseDetails,
      meta: {
        title: '客户案例-邦伲德集团官网'
      }
    }, {
      path: '/certification',
      name: 'Certification',
      component: Certification,
      meta: {
        title: '荣誉资质-邦伲德集团官网'
      }
    }, {
      path: '/CustomerCase',
      name: 'CustomerCase',
      component: CustomerCase,
      meta: {
        title: '客户案例-邦伲德集团官网'
      }
    }, {
      path: '/ExhibitionDetails',
      name: 'ExhibitionDetails',
      component: ExhibitionDetails,
      meta: {
        title: '集团动态-详情-邦伲德集团官网'
      }
    }, {
      path: '/LatestActivity',
      name: 'LatestActivity',
      component: LatestActivity,
      meta: {
        title: '限量神劵-邦伲德集团官网'
      }
    }, {
      path: '/activity',
      name: 'activity',
      component: activity,
      meta: {
        title: '秒杀活动-邦伲德集团官网'
      }
    }, {
      path: '/Position',
      name: 'Position',
      component: Position,
      meta: {
        title: '员工招聘-邦伲德集团官网'
      }
    }, {
      path: '/PositionDetails',
      name: 'PositionDetails',
      component: PositionDetails,
      meta: {
        title: '员工招聘-邦伲德集团官网'
      }
    }, {
      path: '/relation',
      name: 'Relation',
      component: Relation,
      meta: {
        title: '联系我们-邦伲德集团官网'
      }
    }, {
      path: '/scheme',
      name: 'Scheme',
      component: Scheme,
      meta: {
        title: '解决方案-邦伲德集团官网'
      }
    }, {
      path: '/welfare',
      name: 'Welfare',
      component: Welfare,
      meta: {
        title: '加入我们-邦伲德集团官网'
      }
    }, {
      path: '/partner',
      name: 'Partner',
      component: Partner,
      meta: {
        title: '合作伙伴计划-邦伲德集团官网'
      }
    }, {
      path: '/progress',
      name: 'progress',
      component: progress,
      meta: {
        title: '人才发展-邦伲德集团官网'
      }
    }, {
      path: '/presentation',
      name: 'presentation',
      component: presentation,
      meta: {
        title: '集团介绍-邦伲德集团官网'
      }
    }, {
      path: '/cloudservices',
      name: 'cloudservices',
      component: cloudservices,
      meta: {
        title: 'LoT物联网备份-邦伲德集团官网'
      }
    }, {
      path: '/faceRecognition',
      name: 'faceRecognition',
      component: faceRecognition,
      meta: {
        title: '人脸识别-邦伲德集团官网'
      }
    }, {
      path: '/pictureAnalysis',
      name: 'pictureAnalysis',
      component: pictureAnalysis,
      meta: {
        title: '图像识别-邦伲德集团官网'
      }
    }, {
      path: '/characterRecognition',
      name: 'characterRecognition',
      component: characterRecognition,
      meta: {
        title: '文字识别-邦伲德集团官网'
      }
    }, {
      path: '/AutomaticSpeechRecognition',
      name: 'AutomaticSpeechRecognition',
      component: AutomaticSpeechRecognition,
      meta: {
        title: '语音识别-邦伲德集团官网'
      }
    }, {
      path: '/naturalLanguage',
      name: 'naturalLanguage',
      component: naturalLanguage,
      meta: {
        title: '自然语言处理-邦伲德集团官网'
      }
    }, {
      path: '/smart',
      name: 'smart',
      component: smart,
      meta: {
        title: '智能机器人-邦伲德集团官网'
      }
    }, {
      path: '/blockchain',
      name: 'blockchain',
      component: blockchain,
      meta: {
        title: '区块链系统-邦伲德集团官网'
      }
    }, {
      path: '/bigData',
      name: 'bigData',
      component: bigData,
      meta: {
        title: '大数据-邦伲德集团官网'
      }
    }, {
      path: '/customizedDevelopment',
      name: 'customizedDevelopment',
      component: customizedDevelopment,
      meta: {
        title: '定制开发-邦伲德集团官网'
      }
    }, {
      path: '/EcologicalCloud',
      name: 'EcologicalCloud',
      component: EcologicalCloud,
      meta: {
        title: '生态云系统-邦伲德集团官网'
      }
    }, {
      path: '/newRetail',
      name: 'newRetail',
      component: newRetail,
      meta: {
        title: '新零售系统-邦伲德集团官网'
      }
    }, {
      path: '/cloudServer',
      name: 'cloudServer',
      component: cloudServer,
      meta: {
        title: '云服务器-邦伲德集团官网'
      }
    }, {
      path: '/bonnitui',
      name: 'bonnitui',
      component: bonnitui,
      meta: {
        title: '邦伲推-邦伲德集团官网'
      }
    }, {
      path: '/thirdpartnar',
      name: 'thirdpartnar',
      component: thirdpartnar,
      meta: {
        title: '电商代运营-邦伲德集团官网'
      }
    }, {
      path: '/newMediaOperation',
      name: 'newMediaOperation',
      component: newMediaOperation,
      meta: {
        title: '新媒体运营-邦伲德集团官网'
      }
    }, {
      path: '/derivative',
      name: 'derivative',
      component: derivative,
      meta: {
        title: '全业务模式电商解决方案-邦伲德集团官网'
      }
    }, {
      path: '/catering',
      name: 'catering',
      component: catering,
      meta: {
        title: '智慧餐饮解决方案-邦伲德集团官网'
      }
    }, {
      path: '/beauty',
      name: 'beauty',
      component: beauty,
      meta: {
        title: '门店通.美业解决方案-邦伲德集团官网'
      }
    }, {
      path: '/Wenbrigade',
      name: 'Wenbrigade',
      component: Wenbrigade,
      meta: {
        title: '智慧旅游解决方案-邦伲德集团官网'
      }
    }, {
      path: '/education',
      name: 'education',
      component: education,
      meta: {
        title: '智慧教育解决方案-邦伲德集团官网'
      }
    }, {
      path: '/livestreaming',
      name: 'livestreaming',
      component: livestreaming,
      meta: {
        title: '5G直播解决方案-邦伲德集团官网'
      }
    }, {
      path: '/AgencyAccount',
      name: 'AgencyAccount',
      component: AgencyAccount,
      meta: {
        title: '代理记账-邦伲德集团官网'
      }
    }, {
      path: '/Commerce',
      name: 'Commerce',
      component: Commerce,
      meta: {
        title: '工商注册-邦伲德集团官网'
      }
    }, {
      path: '/trademark',
      name: 'trademark',
      component: trademark,
      meta: {
        title: '商标注册-邦伲德集团官网'
      }
    }, {
      path: '/knowledge',
      name: 'knowledge',
      component: knowledge,
      meta: {
        title: '知识产权-邦伲德集团官网'
      }
    }, {
      path: '/video',
      name: 'video',
      component: video,
      meta: {
        title: '视频制作-邦伲德集团官网'
      }
    }, {
      path: '/BuildStation',
      name: 'BuildStation',
      component: BuildStation,
      meta: {
        title: '网站建设-邦伲德集团官网'
      }
    }, {
      path: '/store',
      name: 'store',
      component: store,
      meta: {
        title: '商城系统-邦伲德集团官网'
      }
    }, {
      path: '/webpage',
      name: 'webpage',
      component: webpage,
      meta: {
        title: '网页认证-邦伲德集团官网'
      }
    }, {
      path: '/miniprogram',
      name: 'miniprogram',
      component: miniprogram,
      meta: {
        title: '小程序-邦伲德集团官网'
      }
    }, {
      path: '/assistant',
      name: 'assistant',
      component: assistant,
      meta: {
        title: '公众号助手-邦伲德集团官网'
      }
    }, {
      path: '/note',
      name: 'note',
      component: note,
      meta: {
        title: '消息&短信-邦伲德集团官网'
      }
    }, {
      path: '/SoftTweets',
      name: 'SoftTweets',
      component: SoftTweets,
      meta: {
        title: '软推文-邦伲德集团官网'
      }
    }, {
      path: '/leaflet',
      name: 'leaflet',
      component: leaflet,
      meta: {
        title: '微传单-邦伲德集团官网'
      }
    }, {
      path: '/marketing',
      name: 'marketing',
      component: marketing,
      meta: {
        title: '营销活动-邦伲德集团官网'
      }
    }, {
      path: '/market',
      name: 'market',
      component: market,
      meta: {
        title: '销售系统-邦伲德集团官网'
      }
    }, {
      path: '/design',
      name: 'design',
      component: design,
      meta: {
        title: '云设计-邦伲德集团官网'
      }
    }, {
      path: '/StoreSystem',
      name: 'StoreSystem',
      component: StoreSystem,
      meta: {
        title: '门店系统-邦伲德集团官网'
      }
    }, {
      path: '/EducationSystem',
      name: 'EducationSystem',
      component: EducationSystem,
      meta: {
        title: '教育系统-邦伲德集团官网'
      }
    },
    ]
  }, {
    path: '*',
    name: 'Notfount',
    component: Notfount,
    meta: {
      title: '错误页面-邦伲德集团官网'
    }
  }
]


const router = new VueRouter({
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    let head = document.getElementsByTagName("head");
    let meta = document.createElement("meta");
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute("content", to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }

})

export default router