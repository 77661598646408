import VueI18n from 'vue-i18n'
import Vue from 'vue'
import locale from 'element-ui/lib/locale';
import zh from './lang/zh'
import en from './lang/en'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
Vue.use(VueI18n)
const messages = {
    en: Object.assign(en, enLocale),
    zh: Object.assign(zh, zhLocale)
  }
const i18n = new VueI18n({
    locale: 'zh' || localStorage.getItem('languageSet'),
    messages,
    // messages: {
    //     'zh': require('./lang/zh'),   // 中文语言包
    //     'en': require('./lang/en')    // 英文语言包
    // },
    silentTranslationWarn: true
})
locale.i18n((key, value) => i18n.t(key, value)) 
export default  i18n